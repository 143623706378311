<template>
  <v-card
    id="taskCard"
    class=''
  >
<!--    <v-card-title :style="{ backgroundImage: `url(${imageDef})` }">-->
    <v-card-title>
      <v-img
        :height="80"
        :max-width="120"
        :src="imageDef"
        :elevation="24"
        position="top center"
      ></v-img>
      <v-divider
        vertical
      ></v-divider>
      <v-row>
        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column ms-5">
            <span class="text--primary font-weight-semibold text-truncate text-decoration-none mb-4">
              频道名：{{ taskItem.work.channelName }}
              <v-chip
                small
                :color="resolveTypeVariant(taskItem.work.type).color"
                :class="`${resolveTypeVariant(taskItem.work.type).color}--text`"
                class="v-chip-light-bg font-weight-semibold ms-4"
              >
                {{ resolveTypeVariant(taskItem.work.type).text }}
              </v-chip>
            </span>
            <small class="text--secondary">流程：{{ taskItem.work.name }}</small>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span class="text-truncate text-decoration-none mb-2 text-body-1 font-weight-medium">
              截止时间：{{ taskItem.planEnd }}
            </span>
            <span class="text-truncate text-decoration-none text-body-1 font-weight-medium">
              开始时间：{{ taskItem.planStart }}
            </span>
          </div>
        </v-col>

        <v-col
          class="d-flex justify-center"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            large
            :to="{ name: 'task-todo-submit', query: { workId: taskItem.workId, pointId: taskItem.pointId } }"
          >
            <v-icon>
              {{ icons.mdiPencilOutline }}
            </v-icon>
            处理
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
import {
  mdiPencilOutline,
} from '@mdi/js'

export default {
  name: 'TaskCard',
  props: {
    taskItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const imageDef = require('@/assets/images/avatars/9.png')

    // console.log(imageDef)
    const resolveTypeVariant = type => {
      if (type === 'urgent') return { text: '紧急', color: 'error' }
      if (type === 'ordinary') return { text: '普通', color: 'primary' }
      if (type === 'store_goods') return { text: '囤货', color: 'warning' }

      return { text: '普通', color: 'primary' }
    }

    return {
      imageDef,
      resolveTypeVariant,

      // icons
      icons: {
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
