import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"taskCard"}},[_c(VCardTitle,[_c(VImg,{attrs:{"height":80,"max-width":120,"src":_vm.imageDef,"elevation":24,"position":"top center"}}),_c(VDivider,{attrs:{"vertical":""}}),_c(VRow,[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex flex-column ms-5"},[_c('span',{staticClass:"text--primary font-weight-semibold text-truncate text-decoration-none mb-4"},[_vm._v(" 频道名："+_vm._s(_vm.taskItem.work.channelName)+" "),_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold ms-4",class:((_vm.resolveTypeVariant(_vm.taskItem.work.type).color) + "--text"),attrs:{"small":"","color":_vm.resolveTypeVariant(_vm.taskItem.work.type).color}},[_vm._v(" "+_vm._s(_vm.resolveTypeVariant(_vm.taskItem.work.type).text)+" ")])],1),_c('small',{staticClass:"text--secondary"},[_vm._v("流程："+_vm._s(_vm.taskItem.work.name))])])]),_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-truncate text-decoration-none mb-2 text-body-1 font-weight-medium"},[_vm._v(" 截止时间："+_vm._s(_vm.taskItem.planEnd)+" ")]),_c('span',{staticClass:"text-truncate text-decoration-none text-body-1 font-weight-medium"},[_vm._v(" 开始时间："+_vm._s(_vm.taskItem.planStart)+" ")])])]),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"2"}},[_c(VBtn,{attrs:{"color":"primary","large":"","to":{ name: 'task-todo-submit', query: { workId: _vm.taskItem.workId, pointId: _vm.taskItem.pointId } }}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_vm._v(" 处理 ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }